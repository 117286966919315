import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Container, Input, Table } from 'reactstrap';
import { useDebounceCallback } from 'usehooks-ts';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Pagination from 'Components/Common/Pagination';
import { defaultEvent1 } from 'data/defaultEvent';
import { handleAxiosError } from 'helpers/handleError';
import { secondsToTimeDate } from 'helpers/timeConverter';
import EventCoreService from 'services/event-core.service';
import EventPeriodService from 'services/event-period.service';
import ParticipantService from 'services/participant.service';
import {
  Participant,
  Event,
  EventRole,
  EventPeriod,
  ParticipantUnofficial,
  EventPermissions,
  StudentDocumentForOrganizers,
} from 'types';

import { ReactComponent as FilterIconIOS } from '../../assets/svg/FilterIconIOS.svg';
import { ReactComponent as PersonChecked } from '../../assets/svg/personChecked.svg';
import { ReactComponent as PersonUnchecked } from '../../assets/svg/personUnchecked.svg';
import { ReactComponent as QrIcon } from '../../assets/svg/qrIcon.svg';
import { ReactComponent as StudentCard } from '../../assets/svg/StudentCard.svg';
import FilterModalParticipantList from '../../Components/Common/FilterModalParticipantList';

import AfterScanBarCodeModal from './modal/AfterScanBarCodeModal';
import AfterScanModal from './modal/AfterScanModal';
import AfterScanOfficialStudentModal from './modal/AfterScanOfficialStudentModal';
import NoPeriodModal from './modal/NoPeriodModal';
import ScanBarCodeModal from './modal/ScanBarCodeModal';
import ScanModal from './modal/ScanModal';
import UnauthorizedModal from './modal/UnauthorizedModal';

const limit = 10;

export type EventManagementFilter = {
  order: 'asc' | 'desc';
  attendanceStatus: boolean | undefined;
  participantStatus: 'official' | 'un-official';
};

export const defaultEventManagementFilter: EventManagementFilter = {
  order: 'asc',
  attendanceStatus: undefined,
  participantStatus: 'official',
};

const EventManagement = () => {
  const permissionToScan = [EventPermissions.LEADER, EventPermissions.REGISTER_ATTENDANCE];
  const { id } = useParams();
  const [event, setEvent] = useState<Event>(defaultEvent1);
  const [eventPeriods, setEventPeriods] = useState<EventPeriod[]>([]);
  const [activeEventPeriods, setActiveEventPeriods] = useState<EventPeriod[]>([]);
  const [roles, setRoles] = useState<EventRole[]>([]);
  const [participantList, setParticipantList] = useState<Array<Participant>>([]);
  const [unofficialParticipantList, setUnofficialParticipantList] = useState<
    Array<ParticipantUnofficial>
  >([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [querySearch, setQuerySearch] = useState('');
  const debounceSearch = useDebounceCallback(setQuerySearch, 500);
  const [openScanModal, setOpenScanModal] = useState(false);
  const [openScanBarCodeModal, setOpenScanBarCodeModal] = useState(false);
  const [openAfterScanModal, setOpenAfterScanModal] = useState(false);
  const [openAfterScanBarCodeModal, setOpenAfterScanBarCodeModal] = useState(false);
  // participants
  const [scannedParticipant, setScannedParticipant] = useState<Participant>();
  const [scannedBarCodeParticipant, setScannedBarCodeParticipant] =
    useState<ParticipantUnofficial>();
  const [participantScannedRole, setParticipantScannedRole] = useState<string>('Participant');

  //period
  const [scannedPeriodId, setScannedPeriodId] = useState<string>();
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [openNoPeriodModal, setOpenNoPeriodModal] = useState<boolean>(false);

  // permission
  const [myPermissions, setMyPermissions] = useState<EventPermissions[]>([]);
  const [showAuthorizationModal, setShowAuthorizationModal] = useState<boolean>(false);

  // filter modal
  const [filterState, setFilterState] = useState<EventManagementFilter>(
    defaultEventManagementFilter
  );
  const [openFilteModal, setOpenFilterModal] = useState<boolean>(false);
  const [scanningPhaseQr, setScanningPhaseQr] = useState<'Scanning' | 'Processing' | 'Done'>(
    'Scanning'
  );

  const [scannedStudent, setScannedStudent] = useState<StudentDocumentForOrganizers | undefined>(
    undefined
  );
  const [openAfterScanOfficialStudentModal, setOpenAfterScanOfficialStudentModal] = useState(false);

  const navigate = useNavigate();

  const startTime = useMemo(() => secondsToTimeDate(event.startAt), [event]);
  const endTime = useMemo(() => secondsToTimeDate(event.endAt), [event]);

  const getParticipantAttendanceStatus = (
    participant: Participant | ParticipantUnofficial
  ): Array<'Absent' | 'Registered'> => {
    return eventPeriods.map((period) => {
      return participant.attendedPeriods.findIndex(
        (attendedPeriod) => attendedPeriod.period === period._id
      ) !== -1
        ? 'Registered'
        : 'Absent';
    });
  };

  const getRole = (roleId: string): string => {
    const result = roles.find((role) => role._id === roleId);

    return result?.title || 'Participant';
  };

  const searchParticipantEmailOrName = () => {
    const input = document.getElementById('searchbarParticipantEmail') as HTMLInputElement;
    debounceSearch(input.value);
  };

  // const getPariticipantByStudentId = async (studentId: string) => {
  //   try {
  //     setLoading(true);
  //     const { data } = await ParticipantService.getParticipantByStudentId(event._id, studentId);
  //     const { payload } = data;
  //     if (payload) {
  //       setScannedBarCodeParticipant(payload);
  //       setOpenScanBarCodeModal(false);
  //       setOpenAfterScanBarCodeModal(true);
  //       setScanningPhaseQr('Done');
  //     }
  //   } catch (error: unknown) {
  //     handleAxiosError(error, (message) => toast.error(message));
  //   } finally {
  //     setLoading(false);
  //     setIsScanning(false);
  //   }
  // };

  const getParticipantById = async (participantId: string) => {
    try {
      setLoading(true);
      const { data } = await ParticipantService.getParticipantById(event._id, participantId);
      const { payload } = data;
      if (payload) {
        const scannedRole = getRole(payload.role);
        setScannedParticipant(payload);
        setParticipantScannedRole(scannedRole);
        setOpenScanModal(false);
        setOpenAfterScanModal(true);
        setScanningPhaseQr('Done');
      }
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };
  const registerAttendanceUnOffical = async (participantId: string) => {
    try {
      if (!scannedPeriodId) {
        toast.error('Please check the period again!');
        setOpenScanBarCodeModal(true);
        setOpenAfterScanBarCodeModal(false);
        return;
      }

      setLoading(true);

      const { data } = await EventPeriodService.registerAttendanceUnofficial(
        id || '',
        scannedPeriodId,
        participantId
      );
      const { payload } = data;
      await getParticipants();

      toast.success(`Participant ${payload.studentId} has registered successfully!`);
      setOpenScanBarCodeModal(true);
      setOpenAfterScanBarCodeModal(false);
      setScanningPhaseQr('Scanning');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const registerAttendance = async (participantId: string) => {
    try {
      if (!scannedPeriodId) {
        toast.error('Please check the period again!');
        setOpenScanModal(true);
        setOpenAfterScanModal(false);
        return;
      }

      setLoading(true);

      const { data } = await EventPeriodService.registerAttendance(
        id || '',
        scannedPeriodId,
        participantId
      );
      const { payload } = data;
      await getParticipants();

      toast.success(`Participant ${payload.user.name} has registered successfully!`);
      setOpenScanModal(true);
      setOpenAfterScanModal(false);
      setScanningPhaseQr('Scanning');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const registerAttendanceForOfficialStudent = async (userId: string | undefined) => {
    try {
      if (!scannedPeriodId) {
        toast.error('Please check the period again!');
        setOpenScanModal(true);
        setOpenAfterScanModal(false);
        return;
      }
      if (!userId) {
        toast.error('Something went wrong. Please try again.');
        return;
      }
      setLoading(true);

      const participantRole = roles.find((role) => role.title === 'Participant');

      const { data: officialParticipant } = await ParticipantService.createParticipantById(
        event._id,
        participantRole?._id,
        userId
      );

      await EventPeriodService.registerAttendance(
        id || '',
        scannedPeriodId,
        officialParticipant.payload[0]._id
      );

      await getParticipants();

      toast.success(`This participant has registered successfully!`);
      setOpenScanBarCodeModal(true);
      setOpenAfterScanOfficialStudentModal(false);
      setScanningPhaseQr('Scanning');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const getParticipants = useCallback(async () => {
    try {
      setLoading(true);
      const offset = limit * (currentPage - 1);
      const isEmail = querySearch.includes('@');
      const name = isEmail ? '' : querySearch;
      const email = isEmail ? querySearch : '';
      const { data } = await ParticipantService.getParticipantsOfEvent(
        id || '',
        filterState.order,
        limit,
        offset,
        name,
        email,
        filterState.attendanceStatus
      );
      const { payload } = data;
      setParticipantList(payload.participantList);
      setCount(payload.count);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [currentPage, querySearch, id, filterState.order, filterState.attendanceStatus]);

  const getStudentInSystem = useCallback(
    async (studentId: string) => {
      try {
        if (!id) return;
        setLoading(true);
        const { data: student } = await EventCoreService.getStudentByStundentId(studentId);
        if (student.payload && student.payload.userId !== null) {
          const { data: officialParticipant } = await ParticipantService.getParticipantByUserId(
            id,
            student.payload.userId._id
          );
          const { payload } = officialParticipant;
          if (payload) {
            setOpenScanBarCodeModal(false);
            setParticipantScannedRole('Participant');
            setScannedParticipant(payload);
            setOpenAfterScanModal(true);
          } else {
            setScannedStudent(student.payload);
            setOpenScanBarCodeModal(false);
            setOpenAfterScanOfficialStudentModal(true);
          }
        } else {
          const { data: unofficialParticipant } =
            await ParticipantService.getParticipantByStudentId(event._id, studentId);
          const { payload } = unofficialParticipant;
          if (payload) {
            setScannedBarCodeParticipant(payload);
            setOpenScanBarCodeModal(false);
            setOpenAfterScanBarCodeModal(true);
            setScanningPhaseQr('Done');
          }
        }
      } catch (error: unknown) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        setLoading(false);
      }
    },
    [event._id, id]
  );

  const getUnofficialParticipants = useCallback(async () => {
    try {
      if (!id) return;
      setLoading(true);
      const offset = limit * (currentPage - 1);
      const studentId = !!querySearch ? querySearch : '';
      const { data } = await ParticipantService.getUnofficialParticipantsOfEvent({
        eventId: id,
        order: filterState.order,
        limit: limit,
        offset: offset,
        studentId: studentId,
        attended: filterState.attendanceStatus,
      });
      const { payload } = data;
      setUnofficialParticipantList(payload.participantList);
      console.log(payload.participantList);
      setCount(payload.count);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [id, currentPage, querySearch, filterState.order, filterState.attendanceStatus]);

  const getEvent = useCallback(async () => {
    try {
      setLoading(true);
      const mypermissions = await ParticipantService.getMyPermissions(id || '');
      const { data } = await EventCoreService.getEventById(id || '');
      const { payload } = data;
      setEvent(payload);
      // Roles
      setRoles(payload.roles);
      setMyPermissions(mypermissions.data.payload);
      setLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);

  const getEventPeriods = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await EventPeriodService.getEventTimeline(id || '');
      const { payload } = data;
      setEventPeriods(payload);
      setLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);
  const getActiveEventPeriods = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await EventPeriodService.getActivePeriods(id || '');
      const { payload } = data;
      setActiveEventPeriods(payload);
      if (payload.length > 0) {
        setScannedPeriodId(payload[0]._id);
      }
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [id]);

  const handelOpenScanModal = () => {
    if (!permissionToScan.some((permission) => myPermissions.includes(permission))) {
      setShowAuthorizationModal(true);
      return;
    }
    if (activeEventPeriods.length === 0) {
      setOpenNoPeriodModal(true);
      return;
    }
    setOpenScanModal(true);
  };

  const handelOpenScanBarCodeModal = () => {
    if (!permissionToScan.some((permission) => myPermissions.includes(permission))) {
      setShowAuthorizationModal(true);
      return;
    }
    if (activeEventPeriods.length === 0) {
      setOpenNoPeriodModal(true);
      return;
    }
    setOpenScanBarCodeModal(true);
  };

  const handleApplyFilters = (filters: {
    order: 'asc' | 'desc';
    attendanceStatus: boolean | undefined;
    participantStatus: 'official' | 'un-official';
  }) => {
    setFilterState({
      order: filters.order,
      attendanceStatus: filters.attendanceStatus,
      participantStatus: filters.participantStatus,
    });
    setOpenFilterModal(false);
  };
  const handleCancelFilters = () => {
    setOpenFilterModal(false);
  };

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  useEffect(() => {
    if (filterState.participantStatus === 'official') {
      getParticipants();
    } else {
      getUnofficialParticipants();
    }
  }, [filterState.participantStatus, getParticipants, getUnofficialParticipants]);

  useEffect(() => {
    getEventPeriods();
  }, [getEventPeriods]);

  useEffect(() => {
    getActiveEventPeriods();
  }, [getActiveEventPeriods]);

  return (
    <React.Fragment>
      <FilterModalParticipantList
        show={openFilteModal}
        onApplyFilters={handleApplyFilters}
        onCloseClick={handleCancelFilters}
        currentAttendanceStatus={filterState.attendanceStatus}
        currentOrder={filterState.order}
        currentParticipantStatus={filterState.participantStatus}
        fields={['order', 'attendanceStatus', 'participantStatus']}
      />
      <NoPeriodModal
        show={openNoPeriodModal}
        onCloseClick={() => {
          setOpenNoPeriodModal(false);
        }}
      />
      <ScanBarCodeModal
        eventPeriods={activeEventPeriods}
        show={openScanBarCodeModal}
        onCloseClick={() => {
          setOpenScanBarCodeModal(false);
          setScanningPhaseQr('Scanning');
        }}
        scanningPhase={scanningPhaseQr}
        setScanningPhase={setScanningPhaseQr}
        getParticipantByStudentId={getStudentInSystem}
        setScannedPeriodId={setScannedPeriodId}
        isScanning={isScanning}
        setIsScanning={setIsScanning}
      />

      <ScanModal
        eventPeriods={activeEventPeriods}
        show={openScanModal}
        onCloseClick={() => {
          setOpenScanModal(false);
          setScanningPhaseQr('Scanning');
        }}
        scanningPhase={scanningPhaseQr}
        setScanningPhase={setScanningPhaseQr}
        getParticipantById={getParticipantById}
        setScannedPeriodId={setScannedPeriodId}
      />
      <UnauthorizedModal
        show={showAuthorizationModal}
        onCloseClick={() => {
          setShowAuthorizationModal(false);
        }}
      />
      {scannedBarCodeParticipant !== undefined && (
        <AfterScanBarCodeModal
          show={openAfterScanBarCodeModal}
          role={participantScannedRole}
          onCloseClick={() => {
            setOpenAfterScanBarCodeModal(false);
            setOpenScanBarCodeModal(true);
            setScanningPhaseQr('Scanning');
          }}
          participantInfo={scannedBarCodeParticipant}
          registerAttendanceUnOfficial={registerAttendanceUnOffical}
        />
      )}
      {scannedParticipant !== undefined && (
        <AfterScanModal
          show={openAfterScanModal && scannedParticipant !== undefined}
          role={participantScannedRole}
          onCloseClick={() => {
            setOpenAfterScanModal(false);
            setOpenScanModal(true);
            setScanningPhaseQr('Scanning');
          }}
          participantInfo={scannedParticipant}
          registerAttendance={registerAttendance}
        />
      )}

      {scannedStudent && scannedStudent.userId && (
        <AfterScanOfficialStudentModal
          show={openAfterScanOfficialStudentModal}
          onCloseClick={() => setOpenAfterScanOfficialStudentModal(false)}
          role={'Participant'}
          student={scannedStudent}
          registerAttendanceForOfficialStudent={registerAttendanceForOfficialStudent}
        />
      )}

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Event' breadcrumbItem='Event Management' backTo='/event' />
        </Container>
        <Col>
          <Card className='tw-relative tw-flex tw-flex-col'>
            <div className='tw-relative tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-items-center tw-justify-between tw-px-6 tw-py-4 tw-w-full tw-h-[15rem] md:tw-h-[10rem] tw-bg-[#3D4863] tw-rounded-t-lg tw-text-white'>
              <div className='tw-relative tw-flex tw-flex-col tw-gap-2'>
                <div className='tw-font-bold tw-relative tw-text-[1.25rem]'>{event.title}</div>
                <div className='tw-relative tw-max-w-[30rem] tw-max-h-[5.75rem] md:tw-max-h-[3rem] tw-overflow-x-scroll no-scrollbar'>
                  {event.description}
                </div>
                <div className='tw-flex tw-relative tw-flex-row tw-gap-2'>
                  <div className='tw-font-bold'>From:</div>
                  <div>{startTime}</div>
                  <div className='tw-font-bold'>to:</div>
                  <div>{endTime}</div>
                </div>
              </div>
              <div className='tw-relative tw-h-full tw-w-full md:tw-w-auto tw-flex tw-flex-row md:tw-flex-col tw-justify-between tw-items-end'>
                <div
                  onClick={() => navigate('/event/' + event._id)}
                  className='tw-relative tw-font-bold tw-cursor-pointer'
                >
                  View detail
                </div>
              </div>
            </div>
            <div className='tw-relative tw-w-full tw-flex tw-flex-col tw-gap-1 tw-p-6'>
              <div className='tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-3'>
                <div className='tw-flex tw-items-start tw-w-full -tw-mt-2'>
                  {filterState.participantStatus === 'official' && (
                    <div className='tw-flex tw-flex-row tw-gap-x-2 tw-items-center tw-justify-center'>
                      <PersonChecked className='tw-w-[24px] tw-h-[24px]' />
                      <h2 className='tw-font-semibold tw-text-[14px] md:tw-text-[16px] tw-text-[#0F9D58] tw-mb-0 tw-select-none'>
                        Official Participants
                      </h2>
                    </div>
                  )}
                  {filterState.participantStatus === 'un-official' && (
                    <div className='tw-flex tw-flex-row tw-gap-x-2 tw-items-center tw-justify-center'>
                      <PersonUnchecked className='tw-w-[24px] tw-h-[24px]' />
                      <h2 className='tw-font-semibold tw-text-[14px] md:tw-text-[16px] tw-text-[#EA4335] tw-mb-0 tw-select-none'>
                        Unofficial Participants
                      </h2>
                    </div>
                  )}
                </div>
                <div className='tw-flex tw-flex-row tw-gap-x-2 tw-w-full'>
                  <div className='tw-flex tw-flex-row tw-gap-x-2'>
                    <div
                      onClick={() => handelOpenScanBarCodeModal()}
                      className='tw-relative tw-items-center tw-justify-center tw-flex tw-h-[3rem] tw-aspect-square tw-border-solid tw-border-[1px] tw-border-[#3D4863] tw-rounded-lg tw-cursor-pointer'
                    >
                      <StudentCard className='tw-relative tw-w-[2rem] tw-h-[2rem] tw-fill-[#3D4863]' />
                    </div>
                    <div
                      onClick={() => handelOpenScanModal()}
                      className='tw-relative tw-items-center tw-justify-center tw-flex tw-h-[3rem] tw-aspect-square tw-border-solid tw-border-[1px] tw-border-[#3D4863] tw-rounded-lg tw-cursor-pointer'
                    >
                      <QrIcon className='tw-relative tw-w-[2rem] tw-h-[2rem] tw-fill-[#3D4863]' />
                    </div>
                  </div>
                  <div className='tw-flex tw-w-full tw-flex-row tw-items-center tw-px-2 tw-relative tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg'>
                    <i className='bx bx-search-alt' />
                    <Input
                      className='!tw-border-0'
                      onKeyUp={searchParticipantEmailOrName}
                      id='searchbarParticipantEmail'
                      type='text'
                      placeholder='Search by email or name...'
                    />
                  </div>
                  <div className='tw-w-fit tw-h-[3rem]'>
                    <button
                      onClick={() => {
                        setOpenFilterModal(true);
                      }}
                      className='tw-rounded-[0.5rem] tw-bg-white tw-w-[3rem] tw-h-full tw-border tw-border-[#3D4863] tw-flex tw-justify-center tw-items-center'
                    >
                      <FilterIconIOS className='tw-w-[30px] tw-h-[30px] tw-mb-0' fill='#3D4863' />
                    </button>
                  </div>
                </div>
              </div>

              {filterState.participantStatus === 'official' && !loading && (
                <div className='table-responsive tw-w-full'>
                  <Table className='project-list-table table-nowrap align-middle table-borderless'>
                    <thead className='tw-bg-[#3D4863] !tw-rounded-t-lg tw-text-white tw-font-bold'>
                      <tr>
                        <th className='!tw-bg-[#3D4863]' scope='col' style={{ width: '100px' }}>
                          #
                        </th>
                        <th className='!tw-bg-[#3D4863]' scope='col'>
                          Name
                        </th>
                        <th className='!tw-bg-[#3D4863]' scope='col'>
                          Email
                        </th>
                        <th className='!tw-bg-[#3D4863]' scope='col'>
                          Role
                        </th>

                        {eventPeriods.map((period) => {
                          return (
                            <th key={period._id} className='!tw-bg-[#3D4863]' scope='col'>
                              {period.title}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {participantList.map((participant: Participant, index) => (
                        <tr key={participant._id}>
                          <td className='text-truncate font-size-14 tw-font-bold'>{index + 1}</td>
                          <td className='text-truncate font-size-14'>{participant.user.name}</td>
                          <td className='text-truncate font-size-14'>{participant.user.email}</td>
                          <td className='text-truncate font-size-14'>
                            {getRole(participant.role)}
                          </td>

                          {getParticipantAttendanceStatus(participant).map(
                            (status, statusIndex) => {
                              return (
                                <td
                                  key={statusIndex}
                                  className={`${
                                    status === 'Registered'
                                      ? 'tw-text-[#0F9D58]'
                                      : 'tw-text-[#A3ACC2]'
                                  } text-truncate tw-font-bold font-size-14`}
                                >
                                  {status}
                                </td>
                              );
                            }
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {filterState.participantStatus === 'un-official' && !loading && (
                <div className='table-responsive tw-w-full'>
                  <Table className='project-list-table table-nowrap align-middle table-borderless'>
                    <thead className='tw-bg-[#3D4863] !tw-rounded-t-lg tw-text-white tw-font-bold'>
                      <tr>
                        <th className='!tw-bg-[#3D4863]' scope='col' style={{ width: '100px' }}>
                          #
                        </th>
                        <th className='!tw-bg-[#3D4863]' scope='col'>
                          Name
                        </th>
                        <th className='!tw-bg-[#3D4863]' scope='col'>
                          Student's Id
                        </th>

                        {eventPeriods.map((period) => {
                          return (
                            <th key={period._id} className='!tw-bg-[#3D4863]' scope='col'>
                              {period.title}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>

                    <tbody>
                      {unofficialParticipantList.map(
                        (unofficialParticipant: ParticipantUnofficial, index) => (
                          <tr key={unofficialParticipant._id}>
                            <td className='text-truncate font-size-14 tw-font-bold'>{index + 1}</td>
                            <td className='text-truncate font-size-14'>Anonymous Student</td>
                            <td className='text-truncate font-size-14'>
                              {unofficialParticipant.studentId}
                            </td>

                            {getParticipantAttendanceStatus(unofficialParticipant).map(
                              (status, statusIndex) => {
                                return (
                                  <td
                                    key={statusIndex}
                                    className={`${
                                      status === 'Registered'
                                        ? 'tw-text-[#0F9D58]'
                                        : 'tw-text-[#A3ACC2]'
                                    } text-truncate tw-font-bold font-size-14`}
                                  >
                                    {status}
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              )}

              <div className='tw-relative tw-self-center tw-mt-4'>
                <Pagination
                  count={count}
                  pageSize={limit}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default EventManagement;
