import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { Button, Input, Modal, ModalBody } from 'reactstrap';

import { Event } from 'types/core';

type PreviewFile = File & {
  preview: string;
};

type RealTimeOptionsModalProps = {
  show: boolean;
  event: Event;
  onCloseClick: () => void;
  onApplyFilters: (
    filters: {
      timeDisplay: 'current' | 'countdown';
      participantsDisplay1: 'current' | 'total' | 'percent';
      participantsDisplay2: 'current' | 'total' | 'percent';
    },
    value: File
  ) => Promise<void>;
  currentTimeDisplay: 'current' | 'countdown';
  currentParticipantsDisplay1: 'current' | 'total' | 'percent';
  currentParticipantsDisplay2: 'current' | 'total' | 'percent';
};

const RealTimeOptionsModal = ({
  show,
  event,
  onCloseClick,
  onApplyFilters,
  currentTimeDisplay,
  currentParticipantsDisplay1,
  currentParticipantsDisplay2,
}: RealTimeOptionsModalProps) => {
  const [selectedFile, setSelectedFile] = useState<PreviewFile | null>(null);

  const [tempTimeFilter, setTempTimeFilter] = useState<'current' | 'countdown'>(currentTimeDisplay);

  const [tempParticipantsDisplay1, setTempParticipantsDisplay1] = useState<
    'current' | 'total' | 'percent'
  >(currentParticipantsDisplay1);
  const [tempParticipantsDisplay2, setTempParticipantsDisplay2] = useState<
    'current' | 'total' | 'percent'
  >(currentParticipantsDisplay2);

  const participantOptions = [
    { value: 'current', label: 'Current Attendees', className: '' },
    { value: 'total', label: 'Total participants', className: 'tw-font-semibold tw-text-primary' },
    {
      value: 'percent',
      label: 'Percentage of attendees',
      className: 'tw-font-semibold tw-text-primary',
    },
  ];

  const handleAcceptedFiles = (files: File[]) => {
    const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    const validImageFiles = files.filter((file) => acceptedImageTypes.includes(file.type));
    if (validImageFiles.length > 0) {
      const previewedFile = Object.assign(files[0], {
        preview: URL.createObjectURL(files[0]),
      }) as PreviewFile;
      setSelectedFile(previewedFile);
    } else {
      toast.error('Please upload a valid image file (JPEG, JPG, or PNG).');
    }
  };

  const handleApplyFilter = () => {
    onApplyFilters(
      {
        timeDisplay: tempTimeFilter,
        participantsDisplay1: tempParticipantsDisplay1,
        participantsDisplay2: tempParticipantsDisplay2,
      },
      selectedFile as File
    );
  };

  useEffect(() => {
    setTempTimeFilter(currentTimeDisplay);
  }, [currentTimeDisplay]);

  useEffect(() => {
    setTempParticipantsDisplay1(currentParticipantsDisplay1);
  }, [currentParticipantsDisplay1]);

  useEffect(() => {
    setTempParticipantsDisplay2(currentParticipantsDisplay2);
  }, [currentParticipantsDisplay2]);

  return (
    <Modal
      isOpen={show}
      toggle={() => {}}
      backdrop='static'
      keyboard={false}
      centered
      size='lg'
      className='tw-rounded-[0.5rem]'
    >
      <ModalBody>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-3'
          aria-label='Close'
        ></button>

        <form className='tw-flex tw-flex-col tw-gap-y-5 tw-items-start'>
          <div>
            <p className='tw-font-bold tw-text-primary tw-text-[28px] tw-mb-4'>Real-time Viewer</p>
            <p className='tw-font-medium tw-text-[#3D4863] tw-text-md'>
              Please select the information to display:
            </p>
          </div>
          <div className='tw-flex tw-flex-col tw-gap-y-6 tw-items-center tw-justify-start tw-w-full'>
            <div className='tw-flex tw-flex-row tw-gap-x-4 md:tw-gap-x-8 tw-justify-stretch tw-items-center tw-w-full'>
              <h2 className='tw-font-bold tw-text-primary tw-text-[14px] md:tw-text-[16px] tw-mb-0 tw-min-w-[80px] md:tw-min-w-[90px]'>
                Time:
              </h2>
              <Input
                id='timeSelect'
                className='form-control'
                name='timeSelect'
                type='select'
                onChange={(e) => {
                  const time = e.target.value;
                  if (time === 'current') setTempTimeFilter('current');
                  else setTempTimeFilter('countdown');
                }}
                value={tempTimeFilter}
                placeholder='Time'
              >
                {tempTimeFilter === 'countdown' ? (
                  <>
                    <option key='countdown' value='countdown'>
                      Countdown Timer
                    </option>
                    <option key='current' value='current'>
                      Current Time
                    </option>
                  </>
                ) : (
                  <>
                    <option key='current' value='current'>
                      Current Time
                    </option>
                    <option key='countdown' value='countdown'>
                      Countdown Timer
                    </option>
                  </>
                )}
              </Input>
            </div>
            <div className='tw-flex tw-flex-row tw-gap-x-4 md:tw-gap-x-8 tw-justify-stretch tw-items-center tw-w-full'>
              <h2 className='tw-font-bold tw-text-primary tw-text-[14px] md:tw-text-[16px] tw-mb-0 tw-min-w-[80px] md:tw-min-w-[90px]'>
                Participants:
              </h2>
              <Input
                id='participantASelect'
                className='form-control tw-w-3/4'
                name='participantASelect'
                type='select'
                onChange={(e) =>
                  setTempParticipantsDisplay1(e.target.value as 'current' | 'total' | 'percent')
                }
                value={tempParticipantsDisplay1}
              >
                {participantOptions
                  .filter((opt) => opt.value !== tempParticipantsDisplay2)
                  .map((opt) => (
                    <option key={opt.value} value={opt.value} className={opt.className}>
                      {opt.label}
                    </option>
                  ))}
              </Input>
            </div>

            <div className='tw-flex tw-flex-row tw-gap-x-4 md:tw-gap-x-8 tw-justify-stretch tw-items-center tw-w-full'>
              <h2 className='tw-font-bold tw-text-primary tw-text-[14px] md:tw-text-[16px] tw-mb-0 tw-min-w-[80px] md:tw-min-w-[90px]'>
                {''}
              </h2>
              <Input
                id='participantBSelect'
                className='form-control tw-w-3/4'
                name='participantBSelect'
                type='select'
                onChange={(e) =>
                  setTempParticipantsDisplay2(e.target.value as 'current' | 'total' | 'percent')
                }
                value={tempParticipantsDisplay2}
              >
                {participantOptions
                  .filter((opt) => opt.value !== tempParticipantsDisplay1)
                  .map((opt) => (
                    <option key={opt.value} value={opt.value} className={opt.className}>
                      {opt.label}
                    </option>
                  ))}
              </Input>
            </div>
          </div>
          <Dropzone
            onDrop={(acceptedFiles: File[]) => {
              handleAcceptedFiles(acceptedFiles);
            }}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className='dropzone tw-w-full'
                style={{ borderColor: '#BEC8E7' }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {!event.displaySettings?.background && !selectedFile ? (
                  <div className='dz-message needsclick'>
                    <div className='mb-3 tw-mt-0'>
                      <i className='display-4 text-muted bx bxs-cloud-upload' />
                    </div>
                    <h4 className='tw-text-[12px] md:tw-text-[16px] tw-text-[#A3ACC2]'>
                      Upload your{' '}
                      <span className='tw-font-semibold tw-text-[#74788D]'>
                        16:9 background image
                      </span>
                    </h4>
                    <h4 className='tw-text-[12px] md:tw-text-[16px] tw-text-[#A3ACC2]'>
                      If you prefer to use the default background, please do not upload a file.
                    </h4>
                  </div>
                ) : selectedFile ? (
                  <div className='tw-flex tw-items-center tw-justify-center'>
                    <div className='tw-w-[80%] tw-h-[80%]'>
                      <img
                        data-dz-thumbnail=''
                        className='img-fluid rounded d-block'
                        src={selectedFile.preview}
                        alt={selectedFile.name}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='tw-flex tw-items-center tw-justify-center'>
                    <div className='tw-w-[80%] tw-h-[80%]'>
                      <img
                        data-dz-thumbnail=''
                        className='img-fluid rounded d-block'
                        src={event.displaySettings?.background}
                        alt='Event background'
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Dropzone>

          <div className='tw-flex tw-flex-row tw-w-full tw-justify-end tw-items-center tw-gap-x-4'>
            {selectedFile !== null && (
              <Button type='button' color='danger' outline onClick={() => setSelectedFile(null)}>
                Delete
              </Button>
            )}
            <Button type='button' color='primary' outline onClick={onCloseClick}>
              Cancel
            </Button>
            <Button
              color='primary'
              onClick={handleApplyFilter}
              className='tw-px-6 tw-w-[80px] tw-py-2 tw-rounded-md tw-font-medium'
            >
              LIVE!
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default RealTimeOptionsModal;
