import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { Event, ResponseData, EventStatus, Participant } from 'types';

const EventReportService = {
  getEventCountOfStatus: async () => {
    return axios.get<ResponseData<{ status: EventStatus; count: number }[]>>(
      `${API_URL}admin/events/statistics/status`
    );
  },

  getEventCountOfTimeInterval: async (interval: 'month' | 'quarter' | 'year') => {
    return axios.get<ResponseData<{ count: number; year: number; month: number }[]>>(
      `${API_URL}admin/events/statistics/time?interval=${interval}`
    );
  },

  getParticipantCountOfEvents: async (limit: number, offset: number, order?: 'asc' | 'desc') => {
    return axios.get<
      ResponseData<{
        eventList: {
          event: Event;
          participantCount: number;
        }[];
        count: number;
      }>
    >(
      `${API_URL}admin/events/statistics/event-participants?limit=${limit}&offset=${offset}&sortBy=participantCount&order=${
        order ? order : 'desc'
      }`
    );
  },

  getParticipantCountOfRoles: async (eventId: string) => {
    return axios.get<ResponseData<{ roleId: string; participantCount: number }[]>>(
      `${API_URL}admin/events/${eventId}/report/role-participants`
    );
  },

  getAttendedParticipantCount: async (eventId: string) => {
    return axios.get<ResponseData<number>>(
      `${API_URL}admin/events/${eventId}/report/attended-participants`
    );
  },

  getAttendedParticipantCountForOrganizers: async (eventId: string) => {
    return axios.get<ResponseData<number>>(
      `${API_URL}events/${eventId}/report/attended-participants`
    );
  },

  getAllParticipantInfo: async (eventId: string) => {
    return axios.get<ResponseData<Participant[]>>(
      `${API_URL}admin/events/${eventId}/report/participants`
    );
  },
};

export default EventReportService;
