import React from 'react';
import { Link } from 'react-router-dom';

import Event from '../../assets/svg/Group596.svg';

const NewFeature = () => {
  return (
    <React.Fragment>
      <div className='tw-w-full tw-mb-[50px] lg:tw-mb-[100px] tw-px-[10px] md:tw-px-[40px] lg:tw-px-[60px]'>
        <div className='tw-justify-center lg:tw-justify-start tw-mx-[20px]'>
          <div className='lg:tw-flex-row lg:tw-flex lg:tw-space-x-[30px] lg:tw-items-center tw-justify-between'>
            <h1 className='tw-text-[28px] md:tw-text-[32px] lg:tw-text-[30px] xl:tw-text-[40px] 2xl:tw-text-[48px] tw-font-extrabold tw-text-[#3D4863] tw-text-center lg:tw-text-left'>
              NEW FEATURE AROUND THE CORNER
            </h1>
            <div
              className='hidden lg:tw-flex-1 '
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <div className='2xl:tw-w-[620px] lg:tw-w-[300px] tw-h-[2px] tw-bg-primary'></div>
            </div>
          </div>
          <p className='tw-text-[14px] xl:tw-text-[18px] 2xl:tw-text-[24px] tw-text-[#3D4863] tw-text-center lg:tw-text-left tw-mt-[6px] tw-mb-[20px]'>
            See what's new in OISP Union App version 1.0.0!
          </p>
        </div>
        <div className='tw-relative tw-flex tw-mx-auto tw-w-full tw-border tw-border-b-[#0B2878] tw-border-solid tw-rounded-[54px] tw-shadow-xl '>
          <div
            className='tw-flex tw-w-full md:tw-w-1/2 tw-items-center tw-justify-center md:tw-pl-10'
            style={{ height: 'auto' }}
          >
            <div className='tw-flex-grow tw-w-full tw-flex tw-flex-col tw-items-center md:tw-items-start tw-justify-center tw-overflow-hidde tw-py-6 md:tw-py-0 tw-gap-[20px] md:tw-gap-[10px] 2xl:tw-gap-[30px]'>
              <div className='tw-flex tw-flex-row tw-space-x-[8px] xl:tw-space-x-[12px] tw-items-center tw-justify-center xl:tw-justify-start'>
                <h1 className='tw-text-[30px] md:tw-text-[32px] xl:tw-text-[40px] 2xl:tw-text-[48px] tw-font-extrabold tw-text-[#3D4863] tw-mb-0'>
                  EVENT
                </h1>
                <div className='2xl:tw-w-[80px] 2xl:tw-h-[42px] xl:tw-h-[36px] tw-w-[50px] md:tw-h-[34px] tw-h-[30px] tw-border tw-border-solid tw-rounded-[8px] tw-bg-primary tw-flex tw-items-center tw-justify-center'>
                  <h1 className='tw-text-white tw-text-center tw-font-extrabold tw-text-[16px] md:tw-text-[18px] xl:tw-text-[20px] 2xl:tw-text-[24px] tw-self-center tw-justify-between tw-mb-0'>
                    NEW
                  </h1>
                </div>
              </div>
              <p className=' tw-w-[80%] md:tw-w-full tw-text-[14px] xl:tw-text-[16px] 2xl:tw-text-[22px] tw-text-[#3D4863] tw-text-center md:tw-text-left tw-overflow-x-auto no-scrollbar md:tw-max-h-[160px] lg:tw-max-h-[130px] xl:tw-max-h-full 2xl:tw-max-h-[260px] tw-h-full '>
                Discover the exciting new event feature of OISP Union App! With this innovative
                addition, you can easily browse, register for, and manage all your events in one
                place!
                <br />
                <br />
                Stay updated with the latest happenings, receive personalized recommendations, and
                never miss out on any opportunities. Whether it's a conference, workshop, or social
                gathering, our event feature ensures a seamless and engaging experience.
              </p>
              <div className='tw-flex tw-items-center xl:tw-items-start tw-justify-center xl:tw-justify-start'>
                <Link to='/event'>
                  <button className='tw-w-[140px] tw-h-[60px] md:tw-w-[120px] md:tw-h-[50px] xl:tw-w-[180px] xl:tw-h-[60px] 2xl:tw-w-[280px] 2xl:tw-h-[80px] tw-border tw-border-solid tw-rounded-[8px] tw-bg-primary tw-text-white tw-text-center tw-font-bold xl:tw-text-[24px] lg:tw-text-[18px]  tw-text-[18px]'>
                    Try it now!
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <img
            className='tw-hidden md:tw-flex tw-justify-center md:tw-w-1/2 tw-h-auto tw-z-50 '
            src={Event}
            alt='Describe for new feature'
            style={{ height: 'auto' }}
          />
          <div className='tw-hidden md:tw-flex tw-bg-white tw-w-[8%] tw-h-[100%] tw-absolute tw-mt-[-10px] tw-right-[-40px] tw-z-10 '></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewFeature;
