import { Modal, ModalBody, TabContent, TabPane } from 'reactstrap';

import { Participant } from 'types';

import defaultAvatar from '../../../assets/images/users/blank_profile.png';
import { ReactComponent as AtSign } from '../../../assets/svg/atsign.svg';
import { ReactComponent as LogoBKFade } from '../../../assets/svg/logoBKfade.svg';
import { ReactComponent as StudentCard } from '../../../assets/svg/StudentCardWhite.svg';
import { ReactComponent as Tick } from '../../../assets/svg/Tick.svg';

type AfterScanModalProps = {
  show: boolean;
  role: string;
  onCloseClick: () => void;
  participantInfo: Participant;
  registerAttendance: (data: string) => Promise<void>;
};

const AfterScanModal = ({
  show,
  role,
  onCloseClick,
  participantInfo,
  registerAttendance,
}: AfterScanModalProps) => {
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      size={window.innerWidth >= 768 ? 'md' : 'sm'}
    >
      <ModalBody
        className='tw-p-4 tw-flex tw-flex-col tw-items-center  tw-justify-between'
        style={{ height: window.innerWidth >= 768 ? '436px' : '400px' }}
      >
        <TabContent className='tw-w-full'>
          <TabPane>
            <div className='tw-flex tw-flex-col tw-gap-[36px] tw-mb-0'>
              <div className='tw-flex tw-flex-row tw-gap-0 tw-items-center tw-justify-center tw-w-full'>
                <div className='tw-w-1/3 tw-h-[180px] md:tw-w-[165px] md:tw-h-[260px] md:tw-rounded-l-[8px] tw-rounded-l-[4px] tw-overflow-hidden '>
                  <img
                    className='tw-w-full tw-h-full tw-object-cover tw-object-center'
                    src={
                      participantInfo && participantInfo.user && participantInfo.user.picture
                        ? participantInfo.user.picture
                        : defaultAvatar
                    }
                    alt='Avatar of student'
                  ></img>
                </div>
                <div className='tw-bg-primary tw-w-2/3 tw-h-[180px] md:tw-w-[320px] md:tw-h-[260px] tw-rounded-r-[4px] md:tw-rounded-r-[8px] tw-relative '>
                  <div className='tw-absolute tw-flex tw-w-full tw-h-full tw-justify-start tw-items-center md:tw-justify-center'>
                    <LogoBKFade className='tw-relative tw-mx-auto tw-my-auto sm:tw-w-[160px] tw-h-[160px] ' />
                    <div className='tw-absolute tw-flex tw-flex-col tw-mx-[28px] tw-gap-y-[20px] md:tw-w-[256px] '>
                      <div className=' md:tw-gap-y-[14px]'>
                        <h2 className=' tw-text-[20px] md:tw-text-[24px] tw-text-left tw-text-white tw-font-bold tw-mb-0  '>
                          Welcome,
                        </h2>
                        <h1 className=' tw-text-[24px] md:tw-text-[40px] tw-text-left tw-text-white tw-font-extrabold tw-mb-0 '>
                          {participantInfo && participantInfo.user && participantInfo.user.name
                            ? participantInfo.user.name
                            : 'Student'}
                          ƒ
                        </h1>
                      </div>
                      <div className='tw-flex tw-flex-col tw-gap-y-[4px]'>
                        <div className='tw-gap-x-[4px] md:tw-gap-x-[6px] tw-flex-row tw-flex tw-items-center '>
                          <Tick />
                          <p className=' tw-text-white tw-font-medium md:tw-text-[16px] tw-mb-0  '>
                            {role ? role : 'Participant'}
                          </p>
                        </div>
                        <div className='tw-gap-x-[4px] md:tw-gap-x-[6px] tw-flex-row tw-flex tw-items-center '>
                          <AtSign />
                          <p className=' tw-text-[12px] tw-text-white tw-font-medium md:tw-text-[16px] tw-mb-0  '>
                            {participantInfo && participantInfo.user && participantInfo.user.email
                              ? participantInfo.user.email
                              : 'update soon'}
                          </p>
                        </div>
                        <div className='tw-gap-x-[4px] md:tw-gap-x-[6px] tw-flex-row tw-flex tw-items-center '>
                          <StudentCard />
                          <p className=' tw-text-[12px] tw-text-white tw-font-medium md:tw-text-[16px] tw-mb-0  '>
                            {participantInfo &&
                            participantInfo.studentInfo &&
                            participantInfo.studentInfo.studentId
                              ? participantInfo?.studentInfo.studentId
                              : 'update soon'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=' tw-flex tw-flex-row tw-w-full tw-gap-x-3 tw-items-center tw-justify-center  '>
                <div className=' tw-w-full md:tw-w-[200px] tw-h-[1px] tw-bg-[#A3ACC2] '></div>
                <p className=' tw-text-[#A3ACC2] tw-font-light tw-mb-0 '>Decision</p>
                <div className='tw-w-full md:tw-w-[200px] tw-h-[1px] tw-bg-[#A3ACC2] '></div>
              </div>
              <div className=' tw-flex tw-flex-col md:tw-flex-row tw-gap-y-[16px] md:tw-gap-y-0 md:tw-gap-x-[36px] tw-items-center tw-justify-center '>
                <button
                  className=' tw-w-full tw-h-[40px] md:tw-w-[224px] md:tw-h-[48px] tw-border-[#EA4335] tw-border-[1px] tw-rounded-[8px] tw-flex tw-items-center tw-justify-center '
                  onClick={() => {
                    onCloseClick();
                  }}
                >
                  <p className='tw-font-bold tw-mb-0 tw-text-[#EA4335] tw-text-center tw-text-[16px]'>
                    Cancel
                  </p>
                </button>
                <button
                  className='tw-w-full md:tw-w-[224px] tw-h-[40px] md:tw-h-[48px] tw-bg-primary tw-rounded-[8px] tw-flex tw-items-center tw-justify-center '
                  onClick={() => {
                    registerAttendance(participantInfo._id);
                  }}
                >
                  <p className='tw-text-white tw-font-bold tw-mb-0 tw-text-center tw-text-[16px]'>
                    Confirm attendance
                  </p>
                </button>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

export default AfterScanModal;
