import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import Loading from 'Components/Common/LoadingIndicator';
import AccessLevelPage from 'pages/AccessLevel';
import AccessLevelDetail from 'pages/AccessLevel/DetailPage';
import ForgotPassword from 'pages/Authentication/ForgotPassword';
import Login from 'pages/Authentication/login';
import Logout from 'pages/Authentication/Logout';
import NoPermission from 'pages/Authentication/NoPermission';
import Register from 'pages/Authentication/register';
import ResetPassword from 'pages/Authentication/ResetPassword';
import Setting from 'pages/Authentication/settings';
import UserProfile from 'pages/Authentication/UserProfile';
import CertificateList from 'pages/Certificate';
import CreateCertificate from 'pages/Certificate/CreateCertificate';
import DetailCertificate from 'pages/Certificate/DetailCertificate';
import PublicCertificate from 'pages/Certificate/PublicCertificate';
import CertificateTemplateList from 'pages/Certificate/template';
import UserCertificateList from 'pages/Certificate/user';
import DetailUserCertificate from 'pages/Certificate/user/DetailCertificate';
import ContactSystem from 'pages/ContactSystem';
import DetailTicket from 'pages/ContactSystem/DetailTicket';
import DetailUnitTicket from 'pages/ContactSystem/DetailUnitTicket';
import CreateEvent from 'pages/Event/CreateEvent';
import EventDetail from 'pages/Event/EventDetail';
import EventList from 'pages/Event/EventList';
import EventManagement from 'pages/Event/EventManagement';
import EventRealTimeDisplay from 'pages/Event/EventRealTimeDisplay';
import EventStatistics from 'pages/Event/manager/EventStatistics';
import EventVerificationRequests from 'pages/Event/manager/EventVerifcationRequests';
import VerificationRequestDetail from 'pages/Event/manager/VerificationRequestDetail';
import EventUser from 'pages/Event/user';
import AllEvent from 'pages/Event/user/AllEvent';
import EventDetailUser from 'pages/Event/user/EventDetail';
import Storage from 'pages/Storage';
import StudentInfo from 'pages/StudentInfo';
import AddStudent from 'pages/StudentInfo/AddStudent';
import BulkUpdate from 'pages/StudentInfo/BulkUpdate';
import EditStudent from 'pages/StudentInfo/EditStudent';
import History from 'pages/StudentInfo/History';
import UnitTicketContact from 'pages/UnitContact/UnitTicketContact';
import UnitManagement from 'pages/UnitManagament';
import CreateUnit from 'pages/UnitManagament/CreateUnit';
import EditUnit from 'pages/UnitManagament/EditUnit';
// import TicketContact from 'pages/UnitContact';
import NotFoundPage from 'pages/Utility/NotFound';
import SupportPage from 'pages/Utility/Support';
import { Permission } from 'types';

import Dashboard from '../pages/Dashboard';
const TemplateEditor = lazy(() => import('pages/Certificate/template/TemplateEditor'));

type RouteObject = {
  path: string;
  component: JSX.Element;
  permissions?: Permission[];
  isManager?: boolean;
  exact?: boolean;
  noLayout?: boolean;
};

const authProtectedRoutes: RouteObject[] = [
  {
    path: '/event/display/:id',
    exact: true,
    component: <EventRealTimeDisplay />,
    noLayout: true,
  },
  { path: '/dashboard', component: <Dashboard /> },
  {
    path: '/student-info/:id',
    exact: true,
    component: <EditStudent />,
    permissions: [Permission.READ_STUDENT_INFO],
  },
  {
    path: '/student-info/add',
    component: <AddStudent />,
    permissions: [Permission.UPDATE_STUDENT_INFO],
  },
  {
    path: '/student-info/bulk',
    component: <BulkUpdate />,
    permissions: [Permission.UPDATE_STUDENT_INFO],
  },
  {
    path: '/student-info/history',
    component: <History />,
    permissions: [Permission.READ_STUDENT_INFO],
  },
  {
    path: '/student-info/',
    exact: true,
    component: <StudentInfo />,
    permissions: [Permission.READ_STUDENT_INFO],
  },
  { path: '/storage', component: <Storage />, isManager: true },
  {
    path: '/access-levels',
    exact: true,
    component: <AccessLevelPage />,
    isManager: true,
  },
  {
    path: '/access-levels/:accessLevelId',
    exact: true,
    component: <AccessLevelDetail />,
    isManager: true,
  },
  {
    path: '/certificate',
    exact: true,
    component: <CertificateList />,
    permissions: [Permission.READ_CERTIFICATE_BATCH],
  },
  {
    path: '/certificate/create',
    exact: true,
    component: <CreateCertificate />,
    permissions: [Permission.CREATE_CERTIFICATE_BATCH],
  },
  {
    path: '/certificate/template',
    exact: true,
    component: <CertificateTemplateList />,
    permissions: [Permission.READ_CERTIFICATE_TEMPLATE],
  },
  {
    path: '/certificate/template/create',
    exact: true,
    component: (
      <Suspense fallback={<Loading />}>
        <TemplateEditor />
      </Suspense>
    ),
    permissions: [Permission.CREATE_CERTIFICATE_TEMPLATE],
  },
  {
    path: '/certificate/:id',
    exact: true,
    component: <DetailCertificate />,
    permissions: [Permission.READ_CERTIFICATE_BATCH],
  },
  {
    path: '/event',
    exact: true,
    component: <EventList />,
    // permissions: [Permission.READ_ALL_EVENT],
  },
  {
    path: '/event/create',
    exact: true,
    component: <CreateEvent />,
    permissions: [Permission.CREATE_EVENT],
  },
  {
    path: '/event/:id',
    exact: true,
    component: <EventDetail />,
    // permissions: [Permission.READ_ALL_EVENT],
  },

  {
    path: '/event/management/:id',
    exact: true,
    component: <EventManagement />,
    // permissions: [Permission.READ_ALL_EVENT],
  },
  {
    path: '/event/statistics',
    exact: true,
    component: <EventStatistics />,
    permissions: [Permission.READ_EVENT_STATISTIC],
  },
  {
    path: '/event/verification-requests',
    exact: true,
    component: <EventVerificationRequests />,
    permissions: [Permission.VERIFY_EVENT],
  },
  {
    path: '/event/verification-requests/:id',
    exact: true,
    component: <VerificationRequestDetail />,
    permissions: [Permission.VERIFY_EVENT],
  },
  {
    path: '/contact-system/:id',
    exact: true,
    component: <DetailTicket />,
    permissions: [Permission.READ_SUPPORT_TICKET],
  },
  {
    path: '/unit-ticket/:id',
    exact: true,
    component: <DetailUnitTicket />,
    permissions: [Permission.READ_SUPPORT_TICKET],
  },
  {
    path: '/contact-system',
    exact: true,
    component: <ContactSystem />,
    permissions: [Permission.READ_SUPPORT_TICKET],
  },
  {
    path: '/unit-management',
    exact: true,
    component: <UnitManagement />,
    permissions: [Permission.READ_UNIT],
  },
  {
    path: '/unit-management/:id',
    exact: true,
    component: <EditUnit />,
    permissions: [Permission.READ_UNIT],
  },
  {
    path: '/unit-management/create',
    exact: true,
    component: <CreateUnit />,
    permissions: [Permission.CREATE_STUDENT_REPORT],
  },
  // {
  //   path: '/unit-contact',
  //   exact: true,
  //   component: <TicketContact />,
  //   isManager: true,
  // },
  {
    path: '/unit-contact/id',
    exact: true,
    component: <UnitTicketContact />,
    isManager: true,
  },
  { path: '/user/certificate', component: <UserCertificateList /> },
  { path: '/user/certificate/:id', component: <DetailUserCertificate /> },
  { path: '/user/event', component: <EventUser /> },
  { path: '/user/event/all', component: <AllEvent /> },
  { path: '/user/event/:id', component: <EventDetailUser /> },
  { path: '/profile', component: <UserProfile /> },
  { path: '/settings', component: <Setting /> },
  { path: '/', exact: true, component: <Navigate to='/dashboard' /> },
];

const publicRoutes: RouteObject[] = [
  { path: '/login', component: <Login /> },
  { path: '/register', component: <Register /> },
  { path: '/nopermission', component: <NoPermission /> },
  { path: '/restore-password', component: <ForgotPassword /> },
  { path: '/forgot-password', component: <ResetPassword /> },
  { path: '/logout', component: <Logout /> },
  { path: '/not-found', component: <NotFoundPage /> },
  { path: '/support', component: <SupportPage /> },
  { path: '/chungnhan/:certificateId', component: <PublicCertificate /> },
  { path: '*', component: <NotFoundPage /> },
];
export { authProtectedRoutes, publicRoutes };
