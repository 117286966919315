import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Breadcrumb, Card, Col, Container, Input, Table } from 'reactstrap';

import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import { handleAxiosError } from 'helpers/handleError';
import EventCoreService from 'services/event-core.service';
import { EventRole, Participant } from 'types';

type VerificationRequestParticipantsProps = {
  verificationId: string | undefined;
  roles: Array<EventRole>;
};

const VerificationRequestParticipants = ({
  verificationId,
  roles,
}: VerificationRequestParticipantsProps) => {
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [participants, setParticipants] = useState<Array<Participant>>([]);
  const [filteredParticipants, setFilteredParticipants] = useState(participants);
  const getParticipantsOfEventVerification = useCallback(async () => {
    try {
      if (!verificationId) return;
      setLoading(true);
      const offset = (currentPage - 1) * limit;
      const { data } = await EventCoreService.getParticipantsOfEventVerification({
        verificationId,
        limit,
        offset,
      });
      const { payload } = data;
      setParticipants(payload.participantList);
      setFilteredParticipants(payload.participantList);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [currentPage, verificationId]);

  useEffect(() => {
    getParticipantsOfEventVerification();
  }, [getParticipantsOfEventVerification]);

  const searchUsers = () => {
    const input = document.getElementById('searchbarParticipant') as HTMLInputElement;
    const filteredList = participants.filter((participant: Participant) => {
      return (
        participant.user.name.toLowerCase().includes(input.value.toLowerCase()) ||
        participant.user.email.toLowerCase().includes(input.value.toLowerCase())
      );
    });
    setFilteredParticipants(filteredList);
  };

  if (loading)
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumb title='Application' breadcrumbItem='Event detail' />
          </Container>
          <Col>
            <Card className='tw-relative tw-p-4 xl:tw-p-[1.5rem] tw-flex tw-flex-col tw-items-center lg:tw-flex-row lg:tw-justify-between'>
              <Loading width={400} height={400} />
            </Card>
          </Col>
        </div>
      </React.Fragment>
    );
  return (
    <div className='tw-relative tw-w-full tw-flex tw-flex-col tw-gap-4 tw-p-6'>
      <div className='tw-font-bold'>Participants</div>
      <div className='tw-flex tw-flex-row tw-items-center tw-px-2 tw-relative tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg'>
        <i className='bx bx-search-alt' />
        <Input
          className='!tw-border-0'
          onKeyUp={searchUsers}
          id='searchbarParticipant'
          type='text'
          placeholder='Search...'
        />
      </div>
      <div className='table-responsive'>
        <Table className='project-list-table table-nowrap align-middle table-borderless'>
          <thead className='tw-font-bold tw-text-white'>
            <tr>
              <th className='!tw-bg-[#3D4863]' scope='col' style={{ width: '100px' }}>
                #
              </th>
              <th className='!tw-bg-[#3D4863]' scope='col'>
                Name
              </th>
              <th className='!tw-bg-[#3D4863]' scope='col'>
                Email
              </th>
              <th className='!tw-bg-[#3D4863]' scope='col'>
                Role
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredParticipants.map((participant: Participant) => (
              <tr key={participant._id}>
                <td className='text-truncate font-size-14'>
                  {participants.indexOf(participant) + 1}
                </td>
                <td className='text-truncate font-size-14'>{participant.user.name}</td>
                <td className='text-truncate font-size-14'>{participant.user.email}</td>
                <td className='text-truncate font-size-14'>
                  {roles.find((role) => role._id === participant.role)?.title || participant.role}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className='tw-relative tw-self-center tw-mt-4'>
        <Pagination
          count={participants.length}
          pageSize={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default VerificationRequestParticipants;
