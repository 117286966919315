import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Container, Modal, ModalBody, Row } from 'reactstrap';

import Loading from 'Components/Common/LoadingIndicator';
import EventCoreService from 'services/event-core.service';
import EventReportService from 'services/event-report.service';
import ParticipantService from 'services/participant.service';
import { Event, EventPermissions, EventStatus } from 'types/core';

import real from '../../assets/images/REAL-TIME VIEWER.png';

type RealTimeFilters = {
  timeDisplay: 'current' | 'countdown';
  participantsDisplay1: 'current' | 'total' | 'percent';
  participantsDisplay2: 'current' | 'total' | 'percent';
};
const useCountdown = (targetTime: number | undefined, isActive: boolean) => {
  const [time, setTime] = useState({
    days: 0,
    hours: '00',
    minutes: '00',
    seconds: '00',
    isOver: false,
  });

  useEffect(() => {
    if (!isActive || !targetTime) return;

    const updateCountdown = () => {
      const now = Date.now();
      const distance = targetTime - now;

      if (distance <= 0) {
        setTime((prev) => ({ ...prev, isOver: true }));
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTime({
        days,
        hours: String(hours).padStart(2, '0'),
        minutes: String(minutes).padStart(2, '0'),
        seconds: String(seconds).padStart(2, '0'),
        isOver: false,
      });
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
  }, [targetTime, isActive]);

  return time;
};

const useCurrentTime = (isActive: boolean) => {
  const [time, setTime] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    if (!isActive) return;

    const updateTime = () => {
      const now = new Date();
      setTime({
        hours: String(now.getHours()).padStart(2, '0'),
        minutes: String(now.getMinutes()).padStart(2, '0'),
        seconds: String(now.getSeconds()).padStart(2, '0'),
      });
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000);
    return () => clearInterval(intervalId);
  }, [isActive]);

  return time;
};

const EventRealTimeDisplay = () => {
  const navigate = useNavigate();
  const handleReturn = () => {
    navigate(-1);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [attendedCount, setAttendedCount] = useState<number>(0);
  const [totalParticipantsCount, setTotalParticipantsCount] = useState<number>(0);
  const { id } = useParams();
  const [event, setEvent] = useState<Event>();
  const [openModalFullScreen, setOpenModalFullScreen] = useState<boolean>(true);
  const [displaySettings, setDisplaySettings] = useState<RealTimeFilters>();
  const [allowDisplay, setAllowDisplay] = useState<boolean | undefined>(undefined);

  const isCountdown = displaySettings?.timeDisplay === 'countdown';
  const countdownTime = useCountdown(event?.startAt, isCountdown);
  const currentTime = useCurrentTime(!isCountdown);

  const checkPermissionsAndEvent = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await ParticipantService.getMyPermissions(id || '');
      const { payload: permissions } = data;
      if (!permissions.includes(EventPermissions.LEADER)) {
        setAllowDisplay(false);
        return;
      }

      setAllowDisplay(true);

      const { data: resEvent } = await EventCoreService.getEventById(id || '');
      const { payload: eventData } = resEvent;

      if (eventData.status !== EventStatus.ON_EVENT) setAllowDisplay(false);

      setEvent(eventData);

      if (eventData.displaySettings) {
        setDisplaySettings(eventData.displaySettings.options);
      }
    } catch (error) {
      console.error(error);
      setAllowDisplay(false);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    checkPermissionsAndEvent();
  }, [checkPermissionsAndEvent]);

  const getAttendedParticipantCount = useCallback(async () => {
    try {
      const { data } = await EventReportService.getAttendedParticipantCountForOrganizers(id || '');
      const { payload } = data;
      setAttendedCount(payload);
    } catch (error) {
      console.error('Error fetching participant count:', error);
    }
  }, [id]);

  const getTotalNumberOfParticipants = useCallback(async () => {
    try {
      const { data } = await ParticipantService.getNumberOfParticipants(id || '');
      const { payload } = data;
      setTotalParticipantsCount(payload);
    } catch (error) {
      console.error('Error fetching total participant count:', error);
    }
  }, [id]);
  const toggleFullscreen = () => {
    document.body.classList.add('fullscreen-enable');
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullscreen) {
        document.documentElement.mozRequestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.cancelFullscreen) {
        document.cancelFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
    const exitHandler = () => {
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        document.body.classList.remove('fullscreen-enable');
        document.removeEventListener('fullscreenchange', exitHandler);
        document.removeEventListener('webkitfullscreenchange', exitHandler);
        document.removeEventListener('mozfullscreenchange', exitHandler);
        document.removeEventListener('MSFullscreenChange', exitHandler);
      }
    };
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);
  };

  const handleOpenFullScreen = () => {
    toggleFullscreen();
    setOpenModalFullScreen(false);
  };

  useEffect(() => {
    if (allowDisplay === true) {
      getAttendedParticipantCount();
      getTotalNumberOfParticipants();
      const intervalId = setInterval(() => {
        getAttendedParticipantCount();
        getTotalNumberOfParticipants();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [getAttendedParticipantCount, getTotalNumberOfParticipants, allowDisplay]);

  const ratio = totalParticipantsCount > 0 ? attendedCount / totalParticipantsCount : 0;
  const percentage = Math.round(ratio * 100);
  const correctPercentage = Math.min(100, Math.max(0, percentage));
  const size = 300;
  const strokeWidth = 30;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (correctPercentage / 100) * circumference;

  if (isLoading || allowDisplay === undefined) {
    return <Loading width={600} height={600} />;
  } else if (
    event?.status === EventStatus.ON_EVENT &&
    allowDisplay === true &&
    allowDisplay !== undefined
  ) {
    return (
      <React.Fragment>
        <Modal
          isOpen={openModalFullScreen}
          toggle={() => {}}
          backdrop='static'
          keyboard={false}
          centered
          size='lg'
          className='tw-rounded-[0.5rem]'
        >
          <ModalBody>
            <form className='tw-flex tw-flex-col tw-gap-y-5 tw-items-start'>
              <span className='tw-font-bold tw-text-[#3D4863] tw-text-2xl'>
                Enable Full-Screen Mode
              </span>
              <p>
                To ensure the best viewing experience, please enable full-screen mode. This will
                allow your presentation to be displayed without distractions.
                <br></br>
                <br></br>
                Would you like to enable full-screen mode now?
              </p>

              <div className='tw-flex tw-flex-row tw-w-full tw-justify-end tw-items-center tw-gap-x-4'>
                <Button
                  color='primary'
                  className='tw-px-6 tw-py-2 tw-rounded-md tw-font-medium'
                  onClick={handleOpenFullScreen}
                >
                  Allow
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>

        <div className='tw-w-screen tw-h-screen tw-bg-[#000000] tw-flex tw-items-center tw-justify-center'>
          <div
            className='tw-relative tw-w-full tw-bg-[#000000]'
            style={{ paddingBottom: '56.25%' }}
          >
            <img
              src={event?.displaySettings?.background || real}
              alt='16:9'
              className='tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-object-cover'
            />
            <div className='tw-absolute tw-left-1/2 tw-top-1/2 tw-transform tw-translate-x-[-50%] tw-translate-y-[-25%] tw-w-[870px] tw-h-[378px] tw-rounded-[35px] tw-mb-6 tw-ml-6 tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-10 '>
              {displaySettings &&
                (displaySettings.participantsDisplay1 === 'current' ||
                  displaySettings.participantsDisplay2 === 'current') && (
                  <div className='tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center'>
                    <div className=' tw-w-[300px] tw-h-[300px] tw-rounded-full tw-border-white tw-border-[30px] tw-flex tw-items-center tw-justify-center '>
                      <h1 className='tw-font-bayon tw-mb-0 tw-text-[140px] tw-text-primary '>
                        {attendedCount}
                      </h1>
                    </div>
                    <h2 className='tw-mb-0 tw-font-bayon tw-text-[32px] tw-text-white tw-tracking-[8px]'>
                      Attendees
                    </h2>
                  </div>
                )}
              {displaySettings &&
                (displaySettings.participantsDisplay1 === 'total' ||
                  displaySettings.participantsDisplay2 === 'total') && (
                  <div className='tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center'>
                    <div className=' tw-w-[300px] tw-h-[300px] tw-rounded-full tw-border-white tw-border-[30px] tw-flex tw-items-center tw-justify-center '>
                      <h1 className='tw-font-bayon tw-mb-0 tw-text-[140px] tw-text-primary '>
                        {totalParticipantsCount}
                      </h1>
                    </div>
                    <h2 className='tw-mb-0 tw-font-bayon tw-text-[32px] tw-text-white tw-tracking-[8px]'>
                      Total
                    </h2>
                  </div>
                )}
              {displaySettings &&
                (displaySettings.participantsDisplay1 === 'percent' ||
                  displaySettings.participantsDisplay2 === 'percent') && (
                  <div className='tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center'>
                    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                      <circle
                        cx={size / 2}
                        cy={size / 2}
                        r={radius}
                        fill='none'
                        stroke='#FFFFFF'
                        strokeWidth={strokeWidth}
                      />
                      <circle
                        cx={size / 2}
                        cy={size / 2}
                        r={radius}
                        fill='none'
                        stroke='#0b2878'
                        strokeWidth={strokeWidth}
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                        transform={`rotate(-90 ${size / 2} ${size / 2})`}
                      />
                      <text
                        x='50%'
                        y='50%'
                        textAnchor='middle'
                        dominantBaseline='central'
                        fontSize='100px'
                        fill='#0b2878'
                        fontFamily='bayon'
                      >
                        {percentage}%
                      </text>
                    </svg>
                    <h2 className='tw-mb-0 tw-font-bayon tw-text-[32px] tw-text-white tw-tracking-[8px]'>
                      Percentage
                    </h2>
                  </div>
                )}
            </div>
            <div className='tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full'>
              <div className='tw-absolute tw-right-0 tw-bottom-0 tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-y-[8px]'>
                <h1 className='tw-mb-0 tw-font-nico tw-text-white tw-tracking-[8px]'>
                  {displaySettings?.timeDisplay === 'countdown' ? 'countdown' : ''}
                </h1>
                <div className='tw-font-bayon tw-text-white tw-text-[100px] tw-mb-6 tw-mr-6 tw-flex-shrink tw-w-[460px] tw-text-center'>
                  {isCountdown ? (
                    countdownTime.isOver ? (
                      'NOW!'
                    ) : countdownTime.days > 0 ? (
                      `${countdownTime.days} ${countdownTime.days === 1 ? 'day' : 'days'}`
                    ) : (
                      <div className='tw-flex tw-items-center tw-justify-center tw-gap-3'>
                        <div className='tw-flex tw-items-center tw-justify-center tw-min-w-[100px]'>
                          {countdownTime.hours}
                        </div>
                        <div className='tw-flex tw-items-center tw-justify-center'>:</div>
                        <div className='tw-flex tw-items-center tw-justify-center tw-min-w-[100px]'>
                          {countdownTime.minutes}
                        </div>
                        <div className='tw-flex tw-items-center tw-justify-center'>:</div>
                        <div className='tw-flex tw-items-center tw-justify-center tw-min-w-[100px]'>
                          {countdownTime.seconds}
                        </div>
                      </div>
                    )
                  ) : (
                    <div className='tw-flex tw-items-center tw-justify-center tw-gap-3'>
                      <div className='tw-flex tw-items-center tw-justify-center tw-min-w-[100px]'>
                        {currentTime.hours}
                      </div>
                      <div className='tw-flex tw-items-center tw-justify-center'>:</div>
                      <div className='tw-flex tw-items-center tw-justify-center tw-min-w-[100px]'>
                        {currentTime.minutes}
                      </div>
                      <div className='tw-flex tw-items-center tw-justify-center'>:</div>
                      <div className='tw-flex tw-items-center tw-justify-center tw-min-w-[100px]'>
                        {currentTime.seconds}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else
    return (
      <div
        className='account-pages my-5 pt-5 d-flex align-items-center justify-content-center'
        style={{ minHeight: '100vh' }}
      >
        <Container>
          <Row className='justify-content-center'>
            <Col lg='6'>
              <div className='text-center mb-5'>
                <h1 className='display-2 fw-medium'>
                  4<i className='bx bx-buoy bx-spin text-primary display-3' />3
                </h1>
                <h4 className='text-uppercase'>
                  Insufficient permissions or event status is not On-event
                </h4>
                <div className='mt-5 text-center'>
                  <button
                    type='button'
                    className='btn btn-primary waves-effect waves-light'
                    onClick={handleReturn}
                  >
                    Return
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
};
export default EventRealTimeDisplay;
